// var app = app || {};
//
// (function($) {
//
//   app.PubView = Backbone.View.extend({
//     el: "#top-container",
//     template: _.template($('script[name=chicken-bone]').html()),
//     initialize: function(){
//       this.render();
//     },
//     render: function() {
//       this.$el.empty();
//       this.$el.html(this.template()).hide().fadeIn(200);
//       app.writingText = new app.PubView();
//     },
//
//   });
//
// })(jQuery);
